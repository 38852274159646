export const ContactCategories = [
  "Software Application Development",
  "Analytics",
  "Development Operations",
  "Strategy Advising",
  "Project Management",
  "Other",
];

export default function constants() {}
