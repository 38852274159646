import React from "react";
import Hero from "../components/sections/Hero";

const Home = () => {
    return (
        <>
            <Hero invertmobile="true" topDivider imagefill="true"/>
        </>
    );
};

export default Home;
