import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";
import ScrollToTop from "./utils/ScrollToTop";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import Offerings from "./views/Offerings";
import About from "./views/About";
import Contact from "./views/Contact";
import Privacy from "./views/Privacy";

import "./assets/bootstrap-grid.min.css";

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize({ trackingId: process.env.TRACKING_ID });

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <ScrollToTop>
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/offerings"
              component={Offerings}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/about"
              component={About}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/contact"
              component={Contact}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/privacy"
              component={Privacy}
              layout={LayoutDefault}
            />
          </Switch>
        </ScrollToTop>
      )}
    />
  );
};

export default App;
