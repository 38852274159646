import React from "react";
import FeaturesSplitPrivacy from "../components/sections/FeaturesSplitPrivacy";

const Privacy = () => {
    return (
        <>
            <FeaturesSplitPrivacy invertmobile="true" topDivider imagefill="true" />
        </>
    );
};

export default Privacy;