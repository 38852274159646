import React from "react";
import FeaturesSplitContact from "../components/sections/FeaturesSplitContact";

const Contact = () => {
    return (
        <>
            <FeaturesSplitContact invertmobile="true" topDivider imagefill="true" />
        </>
    );
};

export default Contact;
