class HttpClient {
  get = async (url) => {
    // get = async (url, isFullUrl = false) => {
    // let fullUrl = (isFullUrl === true) ? url : `${config.baseApiUrl}${url}`;
    let fullUrl = url; // assume full url
    try {
      let response = await fetch(fullUrl, {
        method: "GET",
        // headers: identityService.getAuthorizationHeader()
      });

      return await this.validate(response);
    } catch (e) {
      console.log(e);
      return { ok: false, status_code: 503, data: { message: e } };
    }
  };

  post = async (url, body) => {
    // post = async (url, body, isFullUrl = false) => {
    // let fullUrl = (isFullUrl === true) ? url : `${config.baseApiUrl}${url}`; // leaving commented out for now
    let fullUrl = url; // assume full url
    try {
      let response = await fetch(fullUrl, {
        method: "POST",
        body: body,
        // headers: identityService.getAuthorizationHeader()
      });
      // console.log(await response.json())
      return await this.validate(response);
    } catch (e) {
      console.log(e);
      return { ok: false, status_code: 503, data: { message: e } };
    }
  };

  validate = async (response) => {
    if (response.ok) {
      // console.log('validated successful response');
      return {
        ok: true,
        status_code: response.status,
        data: await response.json(),
      };
    } else {
      // console.log('validated failed response');
      return { ok: false, status_code: response.status, data: undefined };
    }
  };
}

const httpClient = new HttpClient();

export default httpClient;
