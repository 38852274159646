import React, { useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Select from "../elements/Select";
import Button from "../elements/Button";
import emailService from "../../services/EmailService";
import Spinner from "react-spinner-material";
import { ContactCategories } from "../../constants/Constants";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertmobile,
  invertDesktop,
  alignTop,
  imagefill,
  ...props
}) => {
  var [nameInput, setName] = useState("");
  var [companyInput, setCompany] = useState("");
  var [emailInput, setEmail] = useState("");
  var [categoryInput, setCategory] = useState("");
  var [messageInput, setMessage] = useState("");

  var [nameValid, setNameValid] = useState(false);
  var [emailValid, setEmailValid] = useState(false);
  var [categoryValid, setCategoryValid] = useState(false);
  var [messageValid, setMessageValid] = useState(false);

  var [submitMessage, setSubmitMessage] = useState("");
  var [showSubmitMessage, setShowSubmitMessage] = useState(false);
  var [loading, setLoading] = useState(false);

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const inputStyle = {
    borderRadius: "5px",
    backgroundColor: "#25282c",
    color: "white",
  };

  const bootstrapOverride = {
    margin: "auto",
    padding: 0,
  };

  const handleInput = (e) => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        setNameValid(e.target.value !== "");
        break;
      case "company":
        setCompany(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        // from https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
        var pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        setEmailValid(pattern.test(e.target.value));
        break;
      case "category":
        setCategory(e.target.value);
        setCategoryValid(e.target.value !== "");
        break;
      case "message":
        setMessage(e.target.value);
        setMessageValid(e.target.value !== "");
        break;
      default:
        console.log(`Cannot handle target: ${e.target.id}`);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    var generatedMessage = `Name: ${nameInput}\nCompany: ${companyInput}\nEmail: ${emailInput}\nCategory: ${categoryInput}\nMessage: ${messageInput}`;
    var sent = await emailService.sendInternalEmail(
      `${categoryInput} [${new Date().toLocaleString()}]`,
      generatedMessage
    );

    if (sent) {
      setSubmitMessage("Your message was sent! We'll be in touch.");

      setName("");
      setNameValid(false);
      setCompany("");
      setEmail("");
      setEmailValid(false);
      setCategory("");
      setCategoryValid(false);
      setMessage("");
      setMessageValid(false);
    } else {
      setSubmitMessage("Something went wrong. Please try again later.");
    }
    setLoading(false);
    setShowSubmitMessage(true);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="reveal-from-bottom" data-reveal-delay="400">
            <div>{<h1>Contact us</h1>}</div>
            <div>
              <div className="col-md-8 col-sm-12" style={bootstrapOverride}>
                <Input
                  style={inputStyle}
                  id="name"
                  type="text"
                  label={nameValid ? "Name" : "Name *"}
                  value={nameInput}
                  onChange={handleInput}
                  placeholder="Your name"
                ></Input>
              </div>

              <div className="col-md-8 col-sm-12" style={bootstrapOverride}>
                <Input
                  style={inputStyle}
                  id="company"
                  type="text"
                  label="Company"
                  value={companyInput}
                  onChange={handleInput}
                  placeholder="Company name (optional)"
                ></Input>
              </div>

              <div className="col-md-8 col-sm-12" style={bootstrapOverride}>
                <Input
                  style={inputStyle}
                  id="email"
                  type="email"
                  label={emailValid ? "Email" : "Email *"}
                  value={emailInput}
                  onChange={handleInput}
                  placeholder="Your email"
                ></Input>
              </div>

              <div className="col-md-8 col-sm-12" style={bootstrapOverride}>
                <Select
                  style={inputStyle}
                  id="category"
                  type="text"
                  label={
                    categoryValid ? "How can we help?" : "How can we help? *"
                  }
                  value={categoryInput}
                  onChange={handleInput}
                  placeholder="Select one"
                >
                  {ContactCategories.map((i, key) => (
                    <option key={key} value={i}>
                      {i}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="col-md-8 col-sm-12" style={bootstrapOverride}>
                {/* TODO max at N characters? */}
                <Input
                  style={inputStyle}
                  id="message"
                  type="textarea"
                  label={messageValid ? `Message` : "Message *"}
                  placeholder="Describe how we can help you"
                  value={messageInput}
                  onChange={handleInput}
                ></Input>
              </div>

              <div>
                <div className="center-content">
                  <Button
                    style={{ marginTop: "20px" }}
                    disabled={
                      !nameValid ||
                      !emailValid ||
                      !categoryValid ||
                      !messageValid ||
                      loading
                    }
                    color="light"
                    wideMobile
                    onClick={handleSubmit}
                  >
                    Submit
                    <div style={loading ? { marginLeft: "6px" } : null}>
                      <Spinner
                        radius={20}
                        color={"#33363A"}
                        stroke={3}
                        visible={loading}
                      />
                    </div>
                  </Button>
                </div>
              </div>

              {
                <div
                  className="center-content mt-2"
                  style={
                    showSubmitMessage
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {submitMessage}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
