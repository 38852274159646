import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertmobile,
  invertDesktop,
  alignTop,
  imagefill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
            {<h1>About Palatio</h1>}
            Fueled by years of Fortune 100 experience in computer science, research,
            and innovation, enhanced by strategy consulting at one of the largest and most
            prestigious firms in the world, Palatio was created as a venture
            with the goal of touching as many facets of the digital industry as
            possible.
            <br />
            <br />
            Always on the hunt for the next problem to solve and the next
            experience to enhance through the channel of digital solutions and
            innovation, we want to 
            <span>
              <Link to="/contact"> connect with you</Link>
            </span>{" "}
            to see how we can help you level up with our technical and business expertise.
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
