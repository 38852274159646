import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertmobile,
  invertDesktop,
  alignTop,
  imagefill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertmobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
            {<h1>Professional Services</h1>}We want to{" "}
            <span>
              <Link to="/contact">hear from you</Link>
            </span>{" "}
            about how we can help you in your next project, or to answer any
            questions about our portfolio of service offerings.
          </div>
          <div className={splitClasses}>
            <div className="split-item">
              <div
                data-reveal-delay="600"
                // className="split-item-content center-content-mobile reveal-from-left"
                className="split-item-content center-content-mobile reveal-from-bottom"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  IT Solutions
                </div>
                <h3 className="mt-0 mb-12">Technical Consulting</h3>
                <p className="m-0">
                  Innovative software solutions, cloud architecture, advanced
                  analytics, system design, IT infrastructure, full-stack
                  engineering, quality assurance, development operations, and more.
                </p>
              </div>
              <div
                data-reveal-delay="600"
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imagefill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/future.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                data-reveal-delay="700"
                // className="split-item-content center-content-mobile reveal-from-left"
                className="split-item-content center-content-mobile reveal-from-bottom"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Functional advising
                </div>
                <h3 className="mt-0 mb-12">
                  Strategy &amp; Project Management
                </h3>
                <p className="m-0">
                  Full life cycle project planning, research, execution, monitoring,
                  risk assessment, and delivery at a global scale.
                </p>
              </div>
              <div
                data-reveal-delay="700"
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imagefill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/city.jpg")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
