import React from "react";
import FeaturesSplitAbout from "../components/sections/FeaturesSplitAbout";

const About = () => {
    return (
        <>
            <FeaturesSplitAbout invertmobile="true" topDivider imagefill="true" />
        </>
    );
};

export default About;
