import React from "react";
import FeaturesSplitOfferings from "../components/sections/FeaturesSplitOfferings";

const Offerings = () => {
    return (
        <>
            <FeaturesSplitOfferings invertmobile="true" topDivider imagefill="true" />
        </>
    );
};

export default Offerings;
