import httpClient from "./HttpClient";
const config = require("../utils/config.json");

class EmailService {
  // option 0: notif bot to internal address
  async sendInternalEmail(subject, text) {
    let formData = new FormData();
    formData.append("subject", subject);
    formData.append("text", text);
    formData.append("option", 0);
    formData.append("blind", true);
    var sent = await httpClient.post(
      `${config.email.baseApiUrl}/send-option-email`,
      formData
    );
    return sent.ok;
  }

  // option 1: internal bot to external address
  async sendExternalEmail(subject, text, recipients) {
    let formData = new FormData();
    formData.append("subject", subject);
    formData.append("text", text);
    formData.append("option", 1);
    formData.append("recipients", recipients);
    formData.append("blind", true);
    var sent = await httpClient.post(
      `${config.email.baseApiUrl}/send-option-email`,
      formData
    );
    return sent.ok;
  }
}

const emailService = new EmailService();

export default emailService;
